<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Edit Company" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Kode" v-model="data.kode" disabled/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Nama *" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Telp" v-model="data.telp"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Email" v-model="data.email"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <vs-input class="w-full" label-placeholder="Alamat Company" v-model="data.alamat"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input class="w-full" label-placeholder="Penanggung Jawab *" v-model="data.penanggung_jawab"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input class="w-full" label-placeholder="Jabatan Penanggung Jawab *" v-model="data.jabatan_penanggung_jawab"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs opacity-75">Logo</label>
            <vs-input class="w-full" type="file" accept="image/*" v-model="data.fotoTemp"
                      @change="data.foto = $event.target.files[0]"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import CompanyRepository from '@/repositories/master/company-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'CompanyEdit',
  components: { ValidationErrors },
  props: ['isActive', 'item'],
  watch: {
    isActive (value) {
      if (value === true) this.data = Object.assign({}, this.item)
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {}
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      CompanyRepository.update(convertToFormData(this.data))
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
